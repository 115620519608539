import { RootState } from "common/redux";
import { useSelector } from "react-redux";
import { useGetMemberWithUsernameQuery } from "../services/MemberService";
import MemberLinkedEntitiesEnum from "../enums/MemberLinkedEntitiesEnum";

const useGetAuthenticatedMember = (
  forceSkip?: boolean,
  linked_entities?: MemberLinkedEntitiesEnum[]
) => {
  const { hasCredentials, user } = useSelector(
    (state: RootState) => state.auth
  );

  const { data, isLoading, isFetching, error, isError, refetch, isSuccess } =
    useGetMemberWithUsernameQuery(
      { username: user?.email!, linked_entities },
      {
        skip: hasCredentials === false || user?.email === undefined || forceSkip
      }
    );

  return { data, isLoading, isFetching, error, isError, refetch, isSuccess };
};

export default useGetAuthenticatedMember;
