import { initializeApp } from "firebase/app";
import {
  Messaging,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
  MessagePayload
} from "firebase/messaging";

import firebaseConfig from "../../config/firebase.json";
import EnvVars from "../../config/EnvVars";
import { useEffect } from "react";

let messaging: Messaging = null;

export const PushNotificationsHelper_isSupported = () => {
  return isSupported();
};

export const PushNotificationsHelper_getTokenAndSendToServer = () => {
  if (messaging === null) return;
  return getToken(messaging, {
    vapidKey: EnvVars.REACT_APP_WEB_PUSH_CERTIFICATE
  });
};

export const PushNotificationsHelper_init = async () => {
  const supported = await isSupported();
  if (!supported) return;
  const app = initializeApp(firebaseConfig);

  messaging = getMessaging(app);

  onMessage(messaging, (payload) => {
    console.log("Firebase: onMessage ", payload);

    /*const { notification, data } = payload;
    const { title, body } = notification;*/
    //alert("Notification Received: " + JSON.stringify(payload));
  });
};

export const PushNotificationsHelper_destroy = () => {};

export const usePushNotificationForeground = (
  onMessageReceived: (message: MessagePayload) => void
) => {
  useEffect(() => {
    if (messaging === null) return;

    onMessage(messaging, (payload) => {
      onMessageReceived(payload);
    });
  }, []);
};
